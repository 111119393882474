<template>
  <div
    class="input text-input"
    :class="[attrClass, errorMessage ? 'input-error' : '']"
    :style="(attrStyle as StyleValue)"
  >
    <label v-if="label" :for="name" class="label input-label text-input-label">
      {{ label }}
    </label>
    <textarea
      v-if="type === 'textarea'"
      :id="attrs.id as string || (label ? name : undefined)"
      v-model="myValue"
      :name="name"
      class="input-field text-input-field"
      :placeholder="placeholder"
      v-bind="inputAttrs(attrs)"
      @blur="validate()"
      @update:model-value="validate()"
    >
    </textarea>
    <input
      v-else-if="type === 'money'"
      v-model="myValue"
      v-maska:[moneyOptions]
      :name="name"
      type="text"
      class="input-field text-input-field"
      :placeholder="placeholder"
      v-bind="inputAttrs(attrs)"
      data-maska="0.99"
      data-maska-tokens="0:\d:multiple|9:\d:optional"
      @blur="validate()"
      @update:model-value="validate()"
      @keydown="onKeyDownNumber"
    />
    <input
      v-else-if="type === 'percent'"
      v-model="myValue"
      v-maska:[percentOptions]
      :name="name"
      type="text"
      class="input-field text-input-field"
      :placeholder="placeholder"
      v-bind="inputAttrs(attrs)"
      data-maska="0.99"
      data-maska-tokens="0:\d:multiple|9:\d:optional"
      @blur="validate()"
      @update:model-value="validate()"
      @keydown="onKeyDownNumber"
    />
    <input
      v-else
      v-model="myValue"
      :name="name"
      :type="type"
      class="input-field text-input-field"
      :placeholder="placeholder"
      v-bind="inputAttrs(attrs)"
      @blur="validate()"
      @update:model-value="validate()"
    />
    <span v-if="errorMessage" :class="'input-errorContainer'">
      {{ errorMessage }}
    </span>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { useField } from 'vee-validate';
import type { PropType, StyleValue } from 'vue';

import type { Rule } from '@/composables/use-rules';
export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
type Emits = {
  (event: 'update:modelValue', value: string | number): void;
  (event: 'validate', value: boolean | string): void;
};

const emit = defineEmits<Emits>();

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: () => ''
  },
  label: {
    type: String,
    default: () => ''
  },
  placeholder: {
    type: String,
    default: () => ''
  },
  type: {
    type: String,
    default: () => 'text'
  },
  rules: {
    type: Array as PropType<Rule[]>,
    default: () => []
  },
  name: {
    type: String,
    default: () => 'myValue'
  }
});

const { class: attrClass, style: attrStyle, ...attrs } = useAttrs();

const moneyOptions = {
  preProcess: (v: string) => v.replace(/[$,\s]/g, ''),
  postProcess: (v: string) => {
    if (!v) return '';
    const decimals = v.includes('.') ? v.split('.')[1] : false;
    let newValue = Intl.NumberFormat('en-US').format(v);
    if (decimals !== false && !newValue.endsWith(`.${decimals}`)) {
      newValue = newValue.split('.')[0] + '.' + decimals;
    }
    return '$ ' + newValue;
  }
};

const percentOptions = {
  preProcess: (v: string) => v.replace(/[%,\s]/g, ''),
  postProcess: (v: string) => {
    if (!v) return '';
    const decimals = v.includes('.') ? v.split('.')[1] : false;
    let newValue = Intl.NumberFormat('en-US').format(v);
    if (decimals !== false && !newValue.endsWith(`.${decimals}`)) {
      newValue = newValue.split('.')[0] + '.' + decimals;
    }
    return newValue + '%';
  }
};

const myValue = ref(props.modelValue);

function parseValue(value: string) {
  return +(value || '0').replace(/[^0-9\.-]+/g, '');
}

watch(
  () => props.modelValue,
  value => {
    myValue.value = value;
  }
);

watch(
  () => myValue.value,
  value => {
    value =
      (props.type === 'money' || props.type === 'percent') &&
      typeof value === 'string'
        ? parseValue(value)
        : value;
    if (props.modelValue !== value) {
      emit('update:modelValue', value);
    }
  }
);

const {
  errorMessage,
  value: validateValue,
  validate: validateField
} = useField(props.name, useRules(props.rules), { pause: true });

let hasValidated = false;

const validate = async (isExternalCall = false, force = false) => {
  validateValue.value = myValue.value;
  if (!isExternalCall || hasValidated || force) {
    hasValidated = true;
    await validateField();
  }
  if (!isExternalCall) {
    emit('validate', errorMessage.value || false);
  }
  return errorMessage.value || false;
};

function inputAttrs(attrs) {
  const { autocomplete } = attrs;
  if (!autocomplete) {
    return {
      ...attrs,
      autocomplete: 'off'
    };
  } else {
    return attrs;
  }
}

defineExpose({
  validate
});

function onKeyDownNumber(event: KeyboardEvent) {
  if (!['ArrowUp', 'ArrowDown'].includes(event.key)) {
    return;
  }

  const field = event.target as HTMLInputElement;

  const currentValue =
    typeof myValue.value === 'string'
      ? parseValue(myValue.value)
      : myValue.value;

  let newValue = currentValue;

  if (event.key === 'ArrowUp') {
    newValue = currentValue + parseValue(field.step || '1');
  } else if (event.key === 'ArrowDown') {
    newValue = currentValue - parseValue(field.step || '1');
  }

  if (newValue !== currentValue) {
    myValue.value = newValue;
    event.preventDefault();
  }
}
</script>
